import { render, staticRenderFns } from "./TopBar.vue?vue&type=template&id=68cd19b0&scoped=true&"
var script = {}
import style0 from "./TopBar.vue?vue&type=style&index=0&id=68cd19b0&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68cd19b0",
  null
  
)

export default component.exports