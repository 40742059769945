var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-tasks page"},[_c('ContentView',{attrs:{"title":_vm.$t('org.vue.views.project-tasks.title')}},[_c('ApolloQuery',{ref:"tasks",staticClass:"fill-height",attrs:{"query":require('@/graphql/task/tasks.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var loading = ref_result.loading;
return [(loading && (!data || !data.tasks))?_c('VueLoadingIndicator',{staticClass:"overlay"}):(data)?_c('NavContent',{staticClass:"tasks",attrs:{"items":_vm.generateItems(data.tasks)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return _c('TaskItem',{attrs:{"task":item.task,"selected":selected}})}}],null,true)},[_c('div',{staticClass:"list-header",attrs:{"slot":"before"},slot:"before"},[_c('VueInput',{staticClass:"search round",attrs:{"icon-left":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('VueButton',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('org.vue.views.project-tasks.refresh')),expression:"$t('org.vue.views.project-tasks.refresh')"}],staticClass:"icon-button flat",attrs:{"icon-left":"refresh"},on:{"click":function($event){return _vm.refresh()}}})],1)]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }