var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-configurations page"},[_c('ContentView',{staticClass:"limit-width",attrs:{"title":_vm.$t('org.vue.views.project-configurations.title')}},[_c('ApolloQuery',{staticClass:"fill-height",attrs:{"query":require('@/graphql/configuration/configurations.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var loading = ref_result.loading;
return [(loading && (!data || !data.configurations))?_c('VueLoadingIndicator',{staticClass:"overlay"}):(data)?_c('NavContent',{staticClass:"configurations",attrs:{"items":_vm.generateItems(data.configurations)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
var selected = ref.selected;
return _c('ConfigurationItem',{attrs:{"configuration":item.configuration,"selected":selected}})}}],null,true)},[_c('div',{staticClass:"list-header",attrs:{"slot":"before"},slot:"before"},[_c('VueInput',{staticClass:"search round",attrs:{"icon-left":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }