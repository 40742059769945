var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ApolloQuery',{staticClass:"suggestion-bar",attrs:{"query":require('@/graphql/suggestion/suggestions.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.result.data;
return (data)?[_c('SuggestionBarList',{attrs:{"suggestions":_vm.withBuiltins(data.suggestions)}})]:undefined}}],null,true)},[_c('ApolloSubscribeToMore',{attrs:{"document":require('@/graphql/suggestion/suggestionAdded.gql'),"updateQuery":function (previousResult, ref) {
      var subscriptionData = ref.subscriptionData;

      var newSuggestion = subscriptionData.data.suggestionAdded
      if (!previousResult.suggestions) {
        return {
          suggestions: [newSuggestion]
        }
      }
      if (previousResult.suggestions.find(function (s) { return s.id === newSuggestion.id; })) {
        return previousResult
      }
      return {
        suggestions: previousResult.suggestions.concat( [newSuggestion]
        )
      }
    }}}),_c('ApolloSubscribeToMore',{attrs:{"document":require('@/graphql/suggestion/suggestionUpdated.gql')}}),_c('ApolloSubscribeToMore',{attrs:{"document":require('@/graphql/suggestion/suggestionRemoved.gql'),"updateQuery":function (previousResult, ref) {
        var subscriptionData = ref.subscriptionData;

        return ({
      suggestions: previousResult.suggestions ? previousResult.suggestions.filter(
        function (s) { return s.id !== subscriptionData.data.suggestionRemoved.id; }
      ) : []
    });
}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }