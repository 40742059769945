var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-dashboard page",class:{
    customizing: _vm.customizeMode,
    'widget-details-shown': _vm.injected.isWidgetDetailsShown
  }},[_c('ContentView',{attrs:{"title":_vm.$t('org.vue.views.project-dashboard.title')}},[_c('template',{slot:"actions"},[(!_vm.customizeMode)?_c('VueButton',{staticClass:"primary round",attrs:{"icon-left":"edit","label":_vm.$t('org.vue.views.project-dashboard.cutomize')},on:{"click":function($event){_vm.customizeMode = true}}}):_c('VueButton',{staticClass:"primary round",attrs:{"icon-left":"done","label":_vm.$t('org.vue.views.project-dashboard.done')},on:{"click":function($event){_vm.customizeMode = false}}})],1),_c('div',{staticClass:"panes fill-height"},[_c('ApolloQuery',{ref:"widgets",staticClass:"widgets",attrs:{"query":require('@/graphql/widget/widgets.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var ref_result = ref.result;
  var data = ref_result.data;
  var loading = ref_result.loading;
return _c('div',{staticClass:"widgets-wrapper"},[(loading && (!data || !data.widgets))?_c('VueLoadingIndicator',{staticClass:"overlay"}):(data)?_vm._l((data.widgets),function(widget){return _c('Widget',{key:widget.id,attrs:{"widget":widget,"customize-mode":_vm.customizeMode}})}):_vm._e()],2)}}])}),_c('transition',{attrs:{"name":"sidepane"}},[(_vm.customizeMode)?_c('WidgetAddPane',{on:{"close":function($event){_vm.customizeMode = false}}}):_vm._e()],1)],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }