import { render, staticRenderFns } from "./ViewNavMore.vue?vue&type=template&id=2251f1b8&scoped=true&"
var script = {}
import style0 from "./ViewNavMore.vue?vue&type=style&index=0&id=2251f1b8&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2251f1b8",
  null
  
)

export default component.exports