var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"logger-message",class:[
    ("type-" + (_vm.message.type)),
    {
      'has-type': _vm.message.type !== 'log',
      'has-tag': _vm.message.tag,
      pre: _vm.pre
    }
  ]},[(_vm.message.type !== 'log')?_c('div',{staticClass:"type"},[_vm._v(_vm._s(_vm.message.type))]):_vm._e(),(_vm.message.tag)?_c('div',{staticClass:"tag"},[_vm._v(_vm._s(_vm.message.tag))]):_vm._e(),_c('div',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.ansiColors(_vm.message.message))}}),_c('div',{staticClass:"date"},[_vm._v(_vm._s(_vm._f("date")(_vm.message.date)))])])}
var staticRenderFns = []

export { render, staticRenderFns }