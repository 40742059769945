var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"npm-package-search"},[_c('div',{staticClass:"content vue-ui-disable-scroll"},[_c('ais-index',{attrs:{"app-id":"OFCNCOG2CU","api-key":"db283631f89b5b8a10707311f911fd00","index-name":"npm-search","query-parameters":{
        hitsPerPage: _vm.pageSize,
        attributesToRetrieve: [
          'name',
          'description',
          'repository',
          'homepage',
          'version',
          'owner',
          'humanDownloadsLast30Days',
          'keywords'
        ],
        attributesToHighlight: [
          'name',
          'description'
        ],
        analyticsTags: [
          'vue-cli-ui'
        ],
        filters: _vm.filters
      }}},[_c('InstantSearchInput',{ref:"searchInput",attrs:{"placeholder":_vm.$t('org.vue.views.project-plugins-add.tabs.search.search-input')}}),_c('div',{ref:"resultsBox",staticClass:"ais-results-box"},[_c('ais-results',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var result = ref.result;
return _c('PackageSearchItem',{attrs:{"pkg":result,"selected":_vm.selectedIdModel === result.name,"load-metadata":_vm.loadMetadata},nativeOn:{"click":function($event){_vm.selectedIdModel = result.name}}})}}])}),_c('ais-no-results',[_c('div',{staticClass:"vue-ui-empty"},[_c('VueIcon',{staticClass:"huge",attrs:{"icon":"search"}}),_c('div',[_vm._v(_vm._s(_vm.$t('org.vue.views.project-plugins-add.tabs.search.not-found')))])],1)]),_c('InstantSearchPagination',{on:{"page-change":function($event){return _vm.scrollResultsToTop()}}})],1)],1)],1),_c('div',{staticClass:"actions-bar no-padding-x"},[_vm._m(0),_c('div',{staticClass:"vue-ui-spacer"}),_vm._t("more-actions"),_c('VueButton',{staticClass:"big",attrs:{"icon-left":"close","label":_vm.$t('org.vue.views.project-plugins-add.tabs.search.buttons.cancel')},on:{"click":function($event){return _vm.close()}}}),_c('VueButton',{staticClass:"big primary",attrs:{"icon-left":"file_download","label":_vm.selectedIdModel ? _vm.$t('org.vue.views.project-plugins-add.tabs.search.buttons.install', { target: _vm.selectedIdModel }) : _vm.$t('org.vue.views.project-plugins-add.tabs.search.buttons.default-install'),"disabled":!_vm.selectedIdModel,"data-testid":"download-plugin"},on:{"click":function($event){return _vm.install()}}})],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"algolia"},[_c('img',{staticClass:"ais-logo",attrs:{"src":require("@/assets/search-by-algolia.svg")}})])}]

export { render, staticRenderFns }