var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project-select-list"},[_c('ApolloQuery',{attrs:{"query":require('@/graphql/project/projects.gql')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var data = ref_result.data;
var loading = ref_result.loading;
return [(data)?[(data.projects.length)?_c('div',[_c('div',{staticClass:"toolbar"},[_c('VueInput',{staticClass:"round",attrs:{"icon-left":"search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._l(([true, false]),function(favorite){return _c('ListFilter',{key:favorite,attrs:{"list":_vm.filterProjects(data.projects),"filter":function (item) { return !!item.favorite === favorite; }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var list = ref.list;
return [(data.projects.find(function (item) { return item.favorite; }))?_c('div',{staticClass:"cta-text",class:favorite ? 'favorite' : 'other'},[_vm._v(" "+_vm._s(_vm.$t(("org.vue.components.project-select-list.titles." + (favorite ? 'favorite' : 'other'))))+" ")]):_vm._e(),_c('ListSort',{attrs:{"list":list,"compare":_vm.compareProjects},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var list = ref.list;
return _vm._l((list),function(project){return _c('ProjectSelectListItem',{key:project.id,class:{
                      open: _vm.projectCurrent && _vm.projectCurrent.id === project.id
                    },attrs:{"project":project},on:{"remove":function($event){return _vm.removeProject(project)},"favorite":function($event){return _vm.toggleFavorite(project)}},nativeOn:{"click":function($event){return _vm.openProject(project)}}})})}}],null,true)})]}}],null,true)})})],2):_c('div',{staticClass:"vue-ui-empty"},[_c('VueIcon',{staticClass:"empty-icon",attrs:{"icon":"attach_file"}}),_c('div',[_vm._v(_vm._s(_vm.$t('org.vue.components.project-select-list.empty')))])],1)]:(loading)?_c('VueLoadingIndicator',{staticClass:"overlay"}):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }